import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "E:\\Projekty\\tmj\\app\\[locale]\\providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "E:\\Projekty\\tmj\\components\\cookie-banner\\component.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "E:\\Projekty\\tmj\\components\\footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "E:\\Projekty\\tmj\\components\\google-analytics\\component.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Navbar"] */ "E:\\Projekty\\tmj\\components\\navbar.tsx");
;
import(/* webpackMode: "eager" */ "E:\\Projekty\\tmj\\styles\\globals.css");
;
import(/* webpackMode: "eager" */ "E:\\Projekty\\tmj\\node_modules\\next\\font\\google\\target.css?{\"path\":\"config\\\\fonts.ts\",\"import\":\"Manrope\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-sans\"}],\"variableName\":\"fontSans\"}");
;
import(/* webpackMode: "eager" */ "E:\\Projekty\\tmj\\node_modules\\next\\font\\google\\target.css?{\"path\":\"config\\\\fonts.ts\",\"import\":\"Fira_Code\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-mono\"}],\"variableName\":\"fontMono\"}");
