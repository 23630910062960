"use client";

import * as React from "react";
import { NextUIProvider } from "@nextui-org/system";
import { useRouter } from "next/navigation";
import { NextIntlClientProvider } from "next-intl";

import { AppProvider } from "@/contexts/app";

export interface ProvidersProps {
  children: React.ReactNode;
  locale: string;
  messages: Record<string, string>;
}

export function Providers({ children, locale, messages }: ProvidersProps) {
  const router = useRouter();

  return (
    <NextUIProvider navigate={router.push}>
      <NextIntlClientProvider locale={locale} messages={messages}>
        <AppProvider>{children}</AppProvider>
      </NextIntlClientProvider>
    </NextUIProvider>
  );
}
