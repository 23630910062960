"use client";

import { useLocale, useTranslations } from "next-intl";
import { createSharedPathnamesNavigation } from "next-intl/navigation";
import { VisuallyHidden } from "@nextui-org/react";
import clsx from "clsx";
import { MouseEvent, useCallback } from "react";

import ErrorBoundary from "../error-boundary";

import { siteConfig } from "@/config/site";
import {
  IconLanguageCS,
  IconLanguageDE,
  IconLanguageEN,
  IconLanguageRU,
} from "@/icons/svgr";

const flags: Record<string, React.ComponentType> = {
  cs: IconLanguageCS,
  de: IconLanguageDE,
  en: IconLanguageEN,
  ru: IconLanguageRU,
};

interface Props {
  className?: string;
}

export default function LocaleSwitcher({ className }: Props) {
  const { Link, usePathname, useRouter } = createSharedPathnamesNavigation({
    locales: siteConfig.locales,
  });

  const t = useTranslations("locale-switcher");
  const locale = useLocale();
  const pathname = usePathname();
  const router = useRouter();

  const handleLinkOnClick = useCallback(
    (locale: string) => (e: MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      e.stopPropagation();
      router.replace(pathname, { locale: locale });
    },
    [pathname, router],
  );

  return (
    <ErrorBoundary>
      <div className={clsx(className, "flex gap-4")}>
        {siteConfig.locales.map((lang) => {
          const Flag = flags[lang as keyof typeof flags] || null;

          return (
            <Link
              key={lang}
              className={clsx({
                "text-neutral-100 pointer-events-none opacity-50":
                  lang === locale,
                "text-white hover:text-primary-200 focus:text-primary-200":
                  lang !== locale,
              })}
              href="/"
              locale={lang}
              onClick={handleLinkOnClick(lang)}
            >
              <VisuallyHidden>{t("locale", { locale: lang })}</VisuallyHidden>
              {Flag && <Flag />}
            </Link>
          );
        })}
      </div>
    </ErrorBoundary>
  );
}
