import clsx from "clsx";
import NextLink from "next/link";
import { useTranslations } from "next-intl";

import { bodyText } from "./primitives";

interface Props {
  className?: string;
}

const ContactNav = ({ className }: Props) => {
  const tContact = useTranslations("contact");
  const t = useTranslations("footer");

  return (
    <div className={clsx("grid grid-cols-3 gap-xl text-white", className)}>
      <div className="grid grid-cols-6 gap-lg lg:gap-sm col-span-3 lg:col-span-1">
        <div className="col-span-2 lg:col-span-6">
          {t.rich("company-address", {
            br: () => <br className="lg:hidden" />,
          })}
        </div>
        <div
          className={clsx(
            bodyText({
              size: "sm",
              color: "gray-light",
            }),
            "col-span-4 lg:col-span-6",
          )}
        >
          Zahradní 223
          <br />
          270 33 Jesenice u Rakovníka
          <br />
          {tContact("czech-republic")}
        </div>
      </div>
      <div className="grid grid-cols-6 gap-lg lg:gap-sm col-span-3 lg:col-span-1">
        <div className="col-span-2 lg:col-span-6">
          {t.rich("billing-information", {
            br: () => <br className="lg:hidden" />,
          })}
        </div>
        <div
          className={clsx(
            bodyText({
              size: "sm",
              color: "gray-light",
            }),
            "col-span-4 lg:col-span-6",
          )}
        >
          {tContact("CIN")}: 052 99 021
          <br />
          {tContact("TIN")}: CZ05299021
        </div>
      </div>
      <div className="grid grid-cols-6 gap-lg lg:gap-sm col-span-3 lg:col-span-1">
        <div className="col-span-2 lg:col-span-6">{t("contacts")}</div>
        <div
          className={clsx(
            bodyText({
              size: "sm",
              color: "gray-light",
            }),
            "col-span-4 lg:col-span-6",
          )}
        >
          <NextLink className="text-[inherit]" href="mailto:info@tmj.cz">
            info@tmj.cz
          </NextLink>
          <br />
          <NextLink className="text-[inherit]" href="tel:+420313521910">
            +420 313 521 910
          </NextLink>
        </div>
      </div>
    </div>
  );
};

export default ContactNav;
