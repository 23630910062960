export type SiteConfig = typeof siteConfig;

export const siteConfig = {
  name: "TMJ.cz",
  description: "Poloautomatické a automatické strojní pásové pily na kov určené jak pro kolmé řezy, tak i pro řezy pod úhlem vyznačující se uzavřenou rámovou konstrukcí, která zaručuje dokonalou tuhost a tím i přesný a kvalitní řez při možnosti využití maximálních technologických podmínek.",
  serverURL: "https://tmj.cz/api/contact",
  navItems: [
    {
      label: "models",
      href: "/models",
    },
    {
      label: "spare-parts-and-service",
      href: "/service",
    },
    {
      label: "about-us",
      href: "/about",
    },
    {
      label: "contacts",
      href: "/contact",
    },
  ],
  navMenuItems: [
    {
      label: "models",
      href: "/models",
    },
    {
      label: "spare-parts-and-service",
      href: "/service",
    },
    {
      label: "about-us",
      href: "/about",
    },
    {
      label: "contacts",
      href: "/contact",
    },
  ],
  locales: ["cs", "en", "de", "ru"],
};
