"use client";

import React, { ReactNode, createContext, useCallback, useState } from "react";

interface AppContextProps {
  isMenuOpen: boolean;
  toggleMenu: () => void;
  setMenuOpen: (isOpen: boolean) => void;
}

const defaultProps: AppContextProps = {
  isMenuOpen: false,
  toggleMenu: () => {},
  setMenuOpen: () => {},
};

export const AppContext = createContext<AppContextProps>(defaultProps);

interface Props {
  children: ReactNode;
}

export const AppProvider: React.FC<Props> = ({ children }) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const toggleMenu = useCallback(() => {
    setIsMenuOpen(!isMenuOpen);
  }, [isMenuOpen]);

  const setMenuOpen = useCallback((isOpen: boolean) => {
    setIsMenuOpen(isOpen);
  }, []);

  return (
    <AppContext.Provider value={{ isMenuOpen, toggleMenu, setMenuOpen }}>
      {children}
    </AppContext.Provider>
  );
};
