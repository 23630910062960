"use client";

import { useEffect, useState } from "react";
import { useTranslations } from "next-intl";
import { Button } from "@nextui-org/react";
import clsx from "clsx";

import { getLocalStorage, setLocalStorage } from "../../lib/localStorage";
import ErrorBoundary from "../error-boundary";
import { bodyText, title } from "../primitives";

export function CookieBanner() {
  const [cookieConsent, setCookieConsent] = useState<
    boolean | null | undefined
  >(undefined);

  const t = useTranslations("cookie-banner");

  useEffect(() => {
    const storedCookieConsent = getLocalStorage("cookie_consent", null);

    setCookieConsent(storedCookieConsent);
  }, [setCookieConsent]);

  useEffect(() => {
    if (cookieConsent === undefined) return;

    const newValue = cookieConsent ? "granted" : "denied";

    if (window.gtag) {
      window.gtag("consent", "update", {
        analytics_storage: newValue,
      });
    }

    setLocalStorage("cookie_consent", cookieConsent);
  }, [cookieConsent]);

  if (cookieConsent !== null) return null;

  return (
    <div className="fixed w-full h-full inset-x-0 bottom-0 z-50 p-6 flex items-end justify-end bg-black bg-opacity-30">
      <div className="flex flex-col items-center gap-y-6 max-w-[455px] bg-white text-center p-8 rounded-xl">
        <div
          className={clsx(
            "max-w-4xl",
            title({
              size: "h3",
              color: "primary",
            }),
          )}
        >
          {t("title")}
        </div>
        <p
          className={clsx(
            "max-w-4xl",
            bodyText({
              size: "sm",
            }),
          )}
        >
          {t("text")}
        </p>

        <div className="flex justify-center gap-x-6">
          <Button
            color="primary"
            radius="full"
            size="lg"
            type="button"
            onClick={() => setCookieConsent(true)}
          >
            {t("button-accept")}
          </Button>
          <Button
            radius="full"
            size="lg"
            type="button"
            variant="bordered"
            onClick={() => setCookieConsent(false)}
          >
            {t("button-denied")}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default function CookieBannerWrapper() {
  return (
    <ErrorBoundary>
      <CookieBanner />
    </ErrorBoundary>
  );
}
