"use client";

import { Button, Image } from "@nextui-org/react";
import NextLink from "next/link";
import { useCallback, useContext } from "react";
import { useLocale, useTranslations } from "next-intl";

import ContactNav from "./contact-nav";

import { bodyText } from "@/components/primitives";
import { IconListSmall } from "@/icons/svgr";
import { button } from "@/components/button";
import { AppContext } from "@/contexts/app";

const Footer = () => {
  const { toggleMenu } = useContext(AppContext);
  const locale = useLocale();

  const t = useTranslations("navigation");

  const handleOnContactButtonPress = useCallback(() => {
    toggleMenu();
  }, [toggleMenu]);

  return (
    <footer className="bg-neutral-900 flex items-center justify-center w-full py-section-xs lg:py-section text-white">
      <div className="container px-lg">
        <div className="flex gap-xl md:gap-3xl">
          <div className="flex lg:flex-col flex-auto gap-md">
            <NextLink className="shrink-0" href={`/${locale}`}>
              <Image
                disableAnimation
                removeWrapper
                alt="TMJ"
                height={48}
                src="/logo-footer.svg"
                width={115}
              />
            </NextLink>
            <div className="flex flex-col gap-xs">
              <div
                className={bodyText({
                  size: "sm",
                  color: "gray-light",
                })}
              >
                © 2024 STROJÍRNA JESENICE s.r.o.
              </div>
              <div className="lg:hidden">
                <Button
                  disableAnimation
                  className={button({
                    color: "white",
                    variant: "link",
                    radius: "none",
                    size: "sm",
                    underline: true,
                  })}
                  startContent={<IconListSmall />}
                  onPress={handleOnContactButtonPress}
                >
                  {t("view-contacts")}
                </Button>
              </div>
            </div>
          </div>
          <ContactNav className="hidden lg:grid flex-auto" />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
