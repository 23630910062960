export function getLocalStorage(
  key: string,
  defaultValue: string | boolean | null,
) {
  try {
    const stickyValue = localStorage.getItem(key);

    return stickyValue !== null && stickyValue !== "undefined"
      ? JSON.parse(stickyValue)
      : defaultValue;
  } catch (error) {
    console.log("error", error);

    return null;
  }
}

export function setLocalStorage(key: string, value: string | boolean | null) {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {}
}
