"use client";

import { usePathname } from "next/navigation";
import {
  Navbar as NextUINavbar,
  NavbarContent,
  NavbarMenu,
  NavbarMenuToggle,
  NavbarBrand,
  NavbarItem,
  NavbarMenuItem,
} from "@nextui-org/react";
import { Link } from "@nextui-org/react";
import { link as linkStyles } from "@nextui-org/theme";
import NextLink from "next/link";
import clsx from "clsx";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Button } from "@nextui-org/react";
import { useLocale, useTranslations } from "next-intl";

import { bodyText } from "./primitives";
import FooterNav from "./contact-nav";
import { button } from "./button";
import LocaleSwitcher from "./locale-switcher/component";

import { siteConfig } from "@/config/site";
import LogoHeader from "@/icons/svgr/logo-header";
import { IconArrowRight, IconCross, IconList } from "@/icons/svgr";
import { AppContext } from "@/contexts/app";

const HOMEPAGE_REGEX = /^\/(cs|en|de|ru)?\/?$/;

export const Navbar = () => {
  const pathname = usePathname();
  const { setMenuOpen, isMenuOpen } = useContext(AppContext);
  const t = useTranslations("navigation");
  const locale = useLocale();
  const navbarRef = useRef<HTMLElement | null>(null);

  const [isTransparent, setIsTransparent] = useState<boolean>(
    HOMEPAGE_REGEX.test(pathname),
  );
  const handleScroll = useCallback(() => {
    if (HOMEPAGE_REGEX.test(pathname)) {
      const windowHeight = window.innerHeight;
      const currentScrollPos = window.scrollY;
      const navbarHeight =
        navbarRef.current?.getBoundingClientRect().height || 0;
      const homepageHeadingPos =
        document.getElementById("homepage-heading")?.getBoundingClientRect()
          .top || 0;
      const mainContentPos =
        document
          .getElementById("homepage-fist-content")
          ?.getBoundingClientRect().top || 0;

      if (windowHeight < 756) {
        setIsTransparent(
          currentScrollPos === 0 || homepageHeadingPos - navbarHeight > 0,
        );
      } else {
        setIsTransparent(mainContentPos - navbarHeight > 0);
      }
    }
  }, [pathname]);

  useEffect(() => {
    setIsTransparent(HOMEPAGE_REGEX.test(pathname));

    setMenuOpen(false);
  }, [pathname, setMenuOpen]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  const handleOnMenuOpenChange = useCallback(
    (isOpen: boolean) => {
      setMenuOpen(isOpen);
    },
    [setMenuOpen],
  );

  return (
    <NextUINavbar
      ref={navbarRef}
      disableAnimation
      classNames={{
        base: [
          "group",
          "fixed",
          "top-0",
          "py-0",
          "transition-[top]",
          "data-[menu-open=true]:bg-white",
          "-mb-[var(--navbar-height)]",

          isTransparent ? "bg-transparent lg:top-2xl z-10" : "bg-white z-50",
        ],
        wrapper: [
          "px-md",
          "lg:px-xl",
          "group-data-[menu-open=true]:border-b",
          "group-data-[menu-open=true]:border-b-neutral-050",
        ],
        content: ["gap-xl"],
        menu: ["px-0", "animate-navigation-mobile"],
        menuItem: [],
      }}
      isBlurred={false}
      isBordered={!isTransparent}
      isMenuOpen={isMenuOpen}
      maxWidth="2xl"
      onMenuOpenChange={handleOnMenuOpenChange}
    >
      <NavbarContent justify="start">
        <NavbarBrand as="li" className="gap-3 max-w-fit">
          <NextLink
            className="flex justify-start items-center gap-1"
            href={`/${locale}`}
            aria-label="TMJ.cz"
          >
            <LogoHeader
              className={clsx(
                "transition-all",
                "group-data-[menu-open=true]:text-primary",
                isTransparent ? "text-white" : "text-primary",
              )}
            />
          </NextLink>
        </NavbarBrand>
        <ul className="hidden lg:flex gap-8 justify-start ml-2">
          {siteConfig.navItems.map((item) => (
            <NavbarItem key={item.href}>
              <NextLink
                className={clsx(
                  linkStyles({ color: "foreground", size: "lg" }),
                  "font-semibold",
                  "data-[active=true]:text-primary data-[active=true]:font-medium",
                  isTransparent && "text-white",
                )}
                color="foreground"
                href={`/${locale}${item.href}`}
              >
                {t(item.label)}
              </NextLink>
            </NavbarItem>
          ))}
        </ul>
      </NavbarContent>

      <LocaleSwitcher className="basis-full justify-end" />

      <NavbarContent className="lg:hidden basis-1" justify="end">
        <NavbarMenuToggle
          className={clsx(
            "w-[32px] h-[32px]",
            "group-data-[menu-open=true]:text-black",
            isTransparent ? "text-white" : "text-black",
          )}
          icon={(isOpen: boolean) => (isOpen ? <IconCross /> : <IconList />)}
        />
      </NavbarContent>

      <NavbarMenu>
        <div className="flex flex-col flex-1 gap-2">
          {siteConfig.navMenuItems.map((item, index) => (
            <NavbarMenuItem key={`${item}-${index}`}>
              <Link
                showAnchorIcon
                anchorIcon={<IconArrowRight />}
                className={clsx(
                  bodyText({
                    size: "xl",
                    weight: "600",
                  }),
                  "flex",
                  "justify-between",
                  "w-full",
                  "px-md",
                  "lg:px-lg",
                  "py-md",
                  "border-b",
                  "border-b-neutral-050",
                )}
                color="foreground"
                href={`/${locale}${item.href}`}
              >
                {t(item.label)}
              </Link>
            </NavbarMenuItem>
          ))}
        </div>

        <div className="bg-neutral-900">
          <div className="flex flex-col gap-xl items-center px-md py-xl">
            <FooterNav className="w-full" />

            <Button
              as={Link}
              className={button({
                color: "white",
                variant: "bordered",
                size: "md",
                radius: "full",
              })}
              color="default"
              endContent={<IconArrowRight />}
              href={`/${locale}/contact`}
            >
              {t("all-contacts")}
            </Button>
          </div>
        </div>
      </NavbarMenu>
    </NextUINavbar>
  );
};
